import { environment } from '../../environments/environment';

export const LangueEnum = {
  FRANCAIS: 'fr-FR',
  ANGLAIS: 'en-GB',
  ESPAGNOL: 'es-ES',
  PORTUGAIS: 'pt-PT',
  ALLEMAND: 'de-DE',
  ITALIEN: 'it-IT',
  TCHEQUE: 'cz-CZ',
  WALLON: 'fr-BE',
  FLAMAND: 'nl-BE',
  SOLVAQUE: 'sk-SK',
  HOLLANDAIS: 'nl-NL',
  INCONNU: 'en-GB',
};
