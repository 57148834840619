import { Component, OnInit } from '@angular/core';
import { environment } from '../environments/environment';
import { NgxSpinnerService } from 'ngx-spinner';
import { Router, NavigationEnd, NavigationStart } from '@angular/router';
import { OAuthService } from 'angular-oauth2-oidc';
import { ApiEnum } from './enums/api.enum';
import { HttpClient } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TranslateService } from '@ngx-translate/core';
import { CookieService } from 'ngx-cookie-service';
import { GeneralEnum } from './enums/general.enum';
import { AuthenticationService } from './services/authentication.service';
import { LangueEnum } from './enums/langue.enum';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.sass'],
})
export class AppComponent implements OnInit {
  title = 'Extranet';
  public url: any;

  constructor(
    private spinner: NgxSpinnerService,
    public router: Router,
    private oauthService: OAuthService,
    private translateService: TranslateService,
    private cookieService: CookieService,
    private authenticationService: AuthenticationService
  ) {
    this.router.events.subscribe((event) => {
      /*
      if (event instanceof NavigationStart) {
        if (event.url.startsWith('/pages') || event.url.startsWith('/catalogue')) {
          const cookie = this.cookieService.check(GeneralEnum.COOKIE_TOKEN_NAME);
          if (cookie) {
            console.log('cookie ok !');
          }
          if (!cookie) {
            this.authenticationService.logout();
            this.router.navigate(['/login']);
          }
        }
      }*/

      if (event instanceof NavigationEnd) {
        this.url = event.url;
      }
    });

    this.oauthService.clientId = environment.client_id;
    this.oauthService.dummyClientSecret = environment.client_secret;
    this.oauthService.scope = 'read';
    this.oauthService.setStorage(sessionStorage);
    this.oauthService.tokenEndpoint = ApiEnum.GET_TOKEN;
    this.oauthService.userinfoEndpoint = ApiEnum.USER_INFO;
    this.oauthService.requireHttps = false;
  }

  ngOnInit() {
    /** spinner starts on init */
    // this.spinner.show();

    /*
    setTimeout(() => {
      this.spinner.hide();
    }, 1000);*/

    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0);
    });

    const storedLangage = localStorage.getItem(GeneralEnum.STORAGE_LANGUAGE);

    if (storedLangage == null || storedLangage === '') {
      let langue = 'en-GB';
      if (
        navigator.language === LangueEnum.ANGLAIS ||
        navigator.language === LangueEnum.FRANCAIS ||
        navigator.language === LangueEnum.ESPAGNOL ||
        navigator.language === LangueEnum.ITALIEN ||
        navigator.language === LangueEnum.ALLEMAND ||
        navigator.language === LangueEnum.HOLLANDAIS
      ) {
        langue = navigator.language;
      }

      this.translateService.use(langue);
      this.translateService.setDefaultLang('en-GB');
    } else {
      this.translateService.use(storedLangage);
      this.translateService.setDefaultLang(storedLangage);
    }
  }
}

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}
