<div class="container min-container">
  <div class="cart-wrapper sec-padding">
    <mat-tab-group #tabGroup>
      <mat-tab label="{{ 'product-search-auto.products'|translate }} ({{getNombreProduits()}})">
        <mat-card *ngIf="produits != null && produits.results != null && produits.results.length > 0" class="p-0">
          <div class="mat-cell text-left" style="padding-top: 10px;">
            <mat-checkbox #check [checked]="produitsEnStock" class="mat-checkbox" (change)="inStockCheckSelect(check.checked)" style="padding-left: 20px;">{{ 'product-search-auto.onlyInStock'|translate }}</mat-checkbox>
          </div>
        </mat-card>
        <mat-card *ngIf="produits != null && produits.results != null && produits.results.length > 0; else noProduct" class="p-0">
          <div class="mat-table cart-table">
            <div class="mat-header-row">
              <div class="mat-header-cell" style="max-width: 150px;">{{ 'product-search-auto.product'|translate }}</div>
              <div class="mat-header-cell-sortable">
                <div class="mat-header-title-block">
                  <span class="mat-header-title">{{ 'product-search-auto.ref'|translate }}</span>
                  <mat-icon *ngIf="sortedReference == null" (click)="trierResultatsParReference()">swap_vert</mat-icon>
                  <mat-icon *ngIf="sortedReference == 'ASC'" (click)="trierResultatsParReference()">arrow_upward</mat-icon>
                  <mat-icon *ngIf="sortedReference == 'DESC'" (click)="trierResultatsParReference()">arrow_downward</mat-icon>
                </div>
              </div>
              <div class="mat-header-cell-sortable" style="max-width: 200px;">
                <div class="mat-header-title-block">
                  <span class="mat-header-title">{{ 'product-search-auto.brand'|translate }}</span>
                  <mat-icon *ngIf="sortedMarque == null" (click)="trierResultatsParMarque()">swap_vert</mat-icon>
                  <mat-icon *ngIf="sortedMarque == 'ASC'" (click)="trierResultatsParMarque()">arrow_upward</mat-icon>
                  <mat-icon *ngIf="sortedMarque == 'DESC'" (click)="trierResultatsParMarque()">arrow_downward</mat-icon>
                </div>
              </div>
              <div class="mat-header-cell" style="max-width: 100px;">{{ 'product-search-auto.available'|translate }}</div>
              <div class="mat-header-cell text-center" style="max-width: 50px;">
                &nbsp;
              </div>
            </div>
            <div *ngFor="let p of produits.results | paginate: { itemsPerPage: produits.results.length, currentPage: page, totalItems: produits.count  }" class="mat-row">
              <div class="mat-cell" style="max-width: 150px;">
                <a class="product-link" (click)="openProductDetailDialog(p)">
                  <img [src]="getImagePrincipale(p, false)" alt="">
                </a>
              </div>
              <div class="mat-cell" style="text-align: left;">
                <a class="product-name" (click)="openProductDetailDialog(p)">{{p.autocomplete | reference:0}} <span class="product-name-second">{{p.autocomplete | reference:1}}</span></a><br>
                <span *ngIf="getLibelleCodeArticle(p) != null" class="product-code-article">{{ 'product-search-auto.articleCodes'|translate }} : {{getLibelleCodeArticle(p)}}</span><br *ngIf="getLibelleCodeArticle(p) != null">
                <a *ngIf="p != null && p.filAriane != null" class="fil-ariane-product" (click)="goToFamille(p.filAriane.familleid)">{{p.filAriane.familledesc}}</a>
                <span *ngIf="p != null && p.filAriane != null" class="fil-ariane-product">&nbsp;->&nbsp;</span>
                <a *ngIf="p != null && p.filAriane != null" class="fil-ariane-product" (click)="goToGroupe(p.filAriane.familleid, p.filAriane.groupeid)">{{p.filAriane.groupedesc}}</a>
                <span *ngIf="p != null && p.filAriane != null" class="fil-ariane-product">&nbsp;->&nbsp;</span>
                <a *ngIf="p != null && p.filAriane != null" class="fil-ariane-product" (click)="goToClasse(p.filAriane.familleid, p.filAriane.groupeid, p.filAriane.classeid)">{{p.filAriane.classedesc}}</a>
              </div>
              <div class="mat-cell" style="max-width: 200px;">{{p.marque}}</div>

              <div class="mat-cell" style="max-width: 100px;">
                <span *ngIf="isEnStock(p); else noStock" class="text-success">{{ 'product-search-auto.inStock'|translate }}</span>
                <ng-template #noStock>
                  <span class="text-danger">{{ 'product-search-auto.onOrder'|translate }}</span>
                </ng-template>
              </div>

              <div class="mat-cell text-center" style="max-width: 50px;">
                <div class="p-1">
                  <mat-icon class="close" (click)="openProductDetailDialog(p)">shopping_cart</mat-icon>
                </div>
              </div>

            </div>
          </div>

        </mat-card>
        <div *ngIf="produits != null && produits.results != null && produits.results.length > 0" fxLayout="row wrap ">
          <div fxFlex="100">
            <mat-card class="p-0 text-center no-shadow">
              <pagination-controls class="product-pagination" autoHide="true" maxSize="5" (pageChange)="onPageChanged($event)" previousLabel="{{ 'product-search-auto.previous'|translate }}" nextLabel="{{ 'product-search-auto.next'|translate }}"></pagination-controls>
            </mat-card>
          </div>
        </div>

        <ng-template #noProduct>
          <div class="container">
            <div class="cart-wrapper sec-padding">


              <div fxLayout="column" fxLayoutAlign="space-around center">
                <mat-chip-list>
                  <mat-chip color="warn" selected="true">{{ 'product-search-auto.noProduct'|translate }}</mat-chip>
                </mat-chip-list>
              </div>
            </div>
          </div>
        </ng-template>
      </mat-tab>

      <mat-tab label="{{ 'product-search-auto.categories'|translate }} ({{filAriane != null && filAriane.length}})">
        <div class="brands search-etim-block">
          <p *ngFor="let fil of filAriane" class="search-etim-item">
            <a [routerLink]="['/catalogue/groups/search']" [queryParams]="{type: 'famille', id: fil.familleid}">{{ fil.familledesc }}</a>
            <span *ngIf="fil.groupid != null && fil.groupdesc != null"> -> </span>
            <a *ngIf="fil.groupid != null && fil.groupdesc != null" [routerLink]="['/catalogue/category/search']" [queryParams]="{type: 'groupe', id: fil.groupid, idFamille: fil.familleid}">{{ fil.groupdesc }}</a>
            <span *ngIf="fil.classid != null && fil.classdesc != null"> -> </span>
            <a *ngIf="fil.classid != null && fil.classdesc != null" [routerLink]="['/catalogue/category/search']" [queryParams]="{type: 'groupe', id: fil.groupid, idFamille: fil.familleid, idClass: fil.classid}">{{ fil.classdesc }}</a>
          </p>
        </div>
      </mat-tab>
    </mat-tab-group>
  </div>
</div>
